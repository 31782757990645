*{
    margin: 0;
    padding: 0;
}

html,
body
{
    /* overflow: hidden; */
    overflow-x: hidden;
    overflow-y: auto;
    font-family: ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}

.loading-bar
{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
}

.loading-bar.ended
{
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform 1.5s ease-in-out;
}

@media only screen and (max-width: 768px) {
  .contact {
    box-sizing: border-box;
    display: none;
    visibility:visible;
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, +95%);
    width: 90%;
    height:fit-content; 
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    border: none;
    z-index: 6;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    opacity: 0.98;
  }

  .impre {
    box-sizing: border-box;
    display: none;
    overflow:auto;
    position: absolute;
    left: 50%;
    top: 0px;
    transform: translate(-50%, +2px);
    width: 90%;
    height: fit-content; 
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    border: none;

    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    opacity: 0.98;

    z-index: 100;  
    
  }
}

@media only screen and (min-width: 769px) {
  .contact {
    position: absolute;
    overflow: auto;
    top: 20px;
    left: 50px;
    width: 300px;

    display: none;
    visibility:visible;

    height: fit-content;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    border: none;
    z-index: 6;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    opacity: 0.98;
  }

  .impre {
    position: absolute;
    overflow: auto;
    top: 20px;
    left: 400px;
    width: 45%;
    display: none;

    box-sizing:border-box;
    height: fit-content; 

    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    border: none;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    opacity: 0.98;
    z-index: 100;  
    
  }
}

  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

.handlebar {
  
  border-top: 4px solid rgb(126, 126, 126);
  border-radius: 2px;
  width: 50px;
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 12px;
}

.kontakt {
  display: block;
  color: rgb(126, 126, 126);
  margin:auto;
  text-align: center;
}

.main_logo{
  padding-top:    20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

  .unsere_Daten {
    padding-top:    30px;
    padding-bottom: 20px;
    padding-left:   20px;
  }

  .kategorien {
    padding-top:    20px;
    padding-bottom: 30px;
    padding-left:   20px;
  }
  
  .fa {
    padding-left:   0px;
    padding-right:  10px;
    padding-bottom:  5px;
  }

h1 {
    display: block;
    font-size: 20px;
    line-height: 26px;
    margin-left: auto;
    margin-right: auto;
    padding-left:   20px;
}

h2 {
  display: block;
  font-size: 20px;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  padding-left:   10px;
  padding-bottom: 20px;
}

h3 {
  display: block;
  font-size: 26px;
  font-weight: normal;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-bottom: 20px;
}

h4 {
  display: block;
  font-size: 22px;
  font-weight: normal;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-bottom: 20px;
}

h5 {
  display: block;
  font-size: 12px;
  font-weight:700;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

h6 {
  display: block;
  font-size: 12px;
  font-weight:normal;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-bottom: 10px;
  text-align: justify;
  padding-right: 15px;
}

.container {
  display: block;
  position: relative;
  margin-bottom: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 30px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: rgb(255, 217, 0);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.unten {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.textfelder input {
  box-sizing: border-box;
  width: 100%;
  background-color: rgb(248, 248, 248);
  border: none;
  padding: 6px;
  padding-left: 12px;
  margin-bottom: 4px;
}

/* Style inputs with type="text", select elements and textareas */
.textfelder textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: none;
  max-height: 150px;
  box-sizing: border-box;
  margin-top: 10px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: none;  /* Allow the user to vertically resize the textarea (not horizontally) */
  background-color: rgb(248, 248, 248);
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(56, 56, 56);
    opacity: 1; /* Firefox */
  }

  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color: rgb(255, 217, 0);
    font-weight: bold;
    color: rgb(87, 87, 87);
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
  }
 
/* When moving the mouse over the submit button, add a darker green color */
input[type=submit]:hover {
  background-color: rgb(255, 196, 0);
}

#impressumID {
  float: right;
  margin-top: 20px;
  background-color: rgb(255, 255, 255);
  color: rgb(44, 44, 44);
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  }

/* .open-button {
    display: none;
    background-color: rgb(255, 196, 0);
    color: white;
    border-radius: 10px;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    top: 23px;
    left: 28px;
    width: 280px;
    max-width: 85%;
    z-index: 2;
}

.open-button:active, .open-button:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
    outline-style: none;
}

.close-button {
    display: none;
    background-color: rgb( 253,  88,  37);
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.9;
    position: fixed;
    top: 23px;
    left: 258px;
    width: 60px;
    max-width: 85%;
    z-index: 9;
} */